import { React, Page, View, Text, StyleSheet, CenterWrapper, Image, onMount, useState, useRef, useUnmount, Spinner } from 'lib'
import { Theme } from 'app'
import GuidePage from '../_guidance'
import { Profile } from 'actions'
import { motion } from 'types/customMotion'
import { useTimeout } from 'beautiful-react-hooks'

import KeyContactsImg from 'assets/permissions_contacts.png'

interface VerifyCodePageProps {
  params: {
    token: string;
  }
}

const initialState = { title: '', description: '' }
const successVerificationText = { title: 'Thank you!', description: 'You have verified your phone number as a Key Contact.' }
const failureVerificationText = { title: 'Invalid code', description: "You can't verify your phone number as a Key Contact with an invalid code." }

function VerifyCodePage({ params }: VerifyCodePageProps) {
  const [verificationMessage, setVerificationMessage] = useState(initialState)

  const mounted = useRef(false)

  // There's a very weird glitch where a component is mounted twice
  useTimeout(() => {
    if (!mounted.current) {
      const success = () => setVerificationMessage(successVerificationText)
      const failure = () => setVerificationMessage(failureVerificationText)
      Profile.verifyToken(params.token, success, failure)
      mounted.current = true
    }
  }, 1000)

  return (
    <Page whiteBackground>
      <View style={styles.wrapper}>
        <Spinner visible={!verificationMessage.title}/>
        <motion.View
          style={styles.innerWrapper}
          variants={expandingSectionVariants}
          animate={verificationMessage.title ? 'show' : 'init'}
          transition={{ bounce: 0.3 }}
        >
          <CenterWrapper style={[styles.innerWrapper]} contentContainerStyle={styles.content}>
            <Image
              source={KeyContactsImg}
              alt='Key Contacts list'
              style={styles.image}
            />
            <View variant={['flex', 'column', 'alignCenter']}>
              <Text variant={['h3', 'marginBottom:2']} text={verificationMessage.title}/>
              <Text variant={[`p2`, 'textCenter']} text={verificationMessage.description}/>
            </View>
          </CenterWrapper>
        </motion.View>

        <View style={styles.guidePageWrapper}>
          <GuidePage isComponent/>
        </View>
      </View>
    </Page>
  )
}

export default VerifyCodePage


const expandingSectionVariants = {
  init: {
    opacity: 0,
    height: 0,
    display: 'none',
  },
  show: {
    opacity: 1,
    height: 'auto',
    duration: 450,
  },
}


const styles = StyleSheet.createComponentStyleSheet({
  wrapper: {
    marginTop: Theme.spacing(20),
    [Theme.media.down('mid')]: {
      marginTop: Theme.spacing(12),

    },
  },
  guidePageWrapper: {
    marginTop: Theme.spacing(12),
    [Theme.media.down('mid')]: {
      marginTop: Theme.spacing(7.5),
    },
  },
  innerWrapper: {
    overflow: 'hidden',
    ...Theme.flex,
    flexDirection: 'column',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  image: {
    maxWidth: 480,
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
})
