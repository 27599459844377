import { React, View, SEO, StyleSheet, CenterWrapper, Link, Text } from 'lib'
import { Theme } from 'app'
import { PageTitle } from 'components'
import { TriggerIcon, ContactsIcon, SMSAlertIcon, FAQIcon } from 'assets/icons'
import { ListVideo } from 'assets/icons'

const header = 'Want to know more about Becon? You are on the right place.'

const guidanceMaterial = [
  {
    icon: <ContactsIcon size={48} color={Theme.colors.grad1}/>,
    title: 'Key Contacts',
    description: 'What to do if you’ve been set up as a Key Contact...',
    link: '/guidance/key-contacts',
  },
  {
    icon: <TriggerIcon size={48} color={Theme.colors.grad1}/>,
    title: 'Safety Triggers',
    description: "How Becon's innovative Safety Triggers work...",
    link: '/guidance/triggers',
  },
  {
    icon: <SMSAlertIcon size={48} color={Theme.colors.grad1}/>,
    title: 'SMS Alerts',
    description: 'What to do if you received an SMS alert from Becon...',
    link: '/guidance/sms-alerts',
  },
  {
    icon: <FAQIcon size={48} color={Theme.colors.grad1}/>,
    title: 'FAQ',
    description: 'Answers to frequently asked questions about Becon, that you may find useful...',
    link: '/faq',
  },
  {
    icon: <ListVideo size={48} color={Theme.colors.grad1}/>,
    title: 'How To Videos',
    description: 'Explainers on how to make the best of Becon...',
    link: '/how-to-use',
  },
]

function Guidance(props) {
  return (
    <View style={[styles.wrapper, props?.isComponent && styles.wrapperAsComponent]}>
      <SEO doNotTrack title={'Guidance'} description={header}/>
      <CenterWrapper style={[styles.wrapper, props?.isComponent && styles.wrapperAsComponent]} contentContainerStyle={styles.content}>
        <PageTitle location='Guidance' title='Get information about:'/>

        <View style={styles.links}>
          {guidanceMaterial.map(({ title, description, icon, link }, key) => (
            <Link key={key} to={link} variant={'flex justifyCenter alignCenter'} style={styles.linkWrapper}>
              {icon}
              <View style={styles.linkDescription} variant={'flex column'}>
                <Text variant={`h5`} text={title}/>
                <Text variant={`p3`} text={description}/>
              </View>
            </Link>
          ))}
        </View>
      </CenterWrapper>
    </View>
  )
}

export default Guidance

const styles = StyleSheet.createComponentStyleSheet({
  wrapper: {
    ...Theme.flex,
    flexDirection: 'column',
    marginTop: Theme.spacing(10),
    marginBottom: Theme.spacing(5),
    [Theme.media.down('mid')]: {
      marginTop: Theme.spacing(6),
      marginBottom: Theme.spacing(5),
    },
  },
  wrapperAsComponent: {
    marginTop: 0,
    [Theme.media.down('mid')]: {
      marginTop: 0,
    },
  },
  content: {
    flexDirection: 'column',
    gap: Theme.spacing(6),
  },
  links: {
    display: 'grid',
    gridTemplateRows: 'repeat(2, 1fr)',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: Theme.spacing(4),

    [Theme.media.down('small')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
      gap: Theme.spacing(2),
    },
  },
  linkWrapper: {
    paddingLeft: Theme.spacing(3),
    paddingRight: Theme.spacing(3),
    paddingTop: Theme.spacing(4),
    paddingBottom: Theme.spacing(4),
    gap: Theme.spacing(3),
    backgroundColor: '#f7f7f7',
    borderRadius: Theme.values.borderRadiusSmall,

    '&:hover': {
      transform: 'translateY(-10px)',
    },
  },
  linkDescription: {
    gap: Theme.spacing(1),
  },
})
